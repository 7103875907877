import { createSlice, current } from '@reduxjs/toolkit'
import { bookHotelThunk, getNearByAirport,lookUpHotelThunk,lookUpHotelReviews, bookHotelPackageThunk, FetchCachedPullPrice, lookUpMuvHotelReviews } from './payment.thunk';

const initialState = {
    bookingSuccess: false,
    loadingBooking: false,
    bookingData: null,
    bookingFailed:false,
    bookingMessage:'',
    nearByAirport: null,
    lookUpData: null,
    reviews:{
        results: null,
        data:null
    },
    muvReviews:{
        results: null,
        data:null
    },
    bookingInfo:null,
    cachedHotel:{
        loading:false,
        data:null
    }
}

export const HotelSlice = createSlice({
    name: 'HotelBooking',
    initialState: initialState,
    reducers: {
        clearHotelBooking: (state, action) => {
            state.bookingData = null;
            state.bookingSuccess = false;
            state.loadingBooking = false
        },
        updateBookingInfo:(state,action)=>{
            state.bookingInfo = action.payload;
        },
        clearHotelReviews: (state, action) => {
            state.reviews.data = null;
            state.reviews.results = null;
        },
        clearMuvHotelReviews: (state, action) => {
            state.muvReviews.data = null;
            state.muvReviews.results = null;
        },
    },
    extraReducers: {
        [bookHotelThunk.pending]: (state, action) => {
            state.loadingBooking = true;
        },
        [bookHotelThunk.fulfilled]: (state, action) => {
            state.bookingData = action.payload;
            state.bookingSuccess = true;
            state.loadingBooking = false;
        },
        [bookHotelThunk.rejected]: (state, action) => {
            state.loadingBooking = false;
            state.bookingSuccess = false;
            state.bookingMessage = action.payload;
            state.bookingFailed = true;  
        },
        [getNearByAirport.pending]: (state, action) => {
        },
        [getNearByAirport.fulfilled]: (state, action) => {
            state.nearByAirport = action.payload;
        },
        [lookUpHotelThunk.pending]: (state, action) => {
        },
        [lookUpHotelThunk.fulfilled]: (state, action) => {
            state.lookUpData = action.payload;
        },  
        [lookUpHotelReviews.pending]: (state, action) => {
        },
        [lookUpHotelReviews.fulfilled]: (state, action) => {
            state.reviews.data = action.payload;

            if(state.reviews.results){
                if(action.payload?.review_data){
                    state.reviews.results = state.reviews.results?.concat(action.payload?.review_data);
                }
            }
            else{
                state.reviews.results = action.payload?.review_data;
            }
           

        },  
        [lookUpHotelReviews.rejected]: (state, action) => {
            state.reviews.data = null;
            state.reviews.results = null;
        }, 
        [lookUpMuvHotelReviews.pending]: (state, action) => {
        },
        [lookUpMuvHotelReviews.fulfilled]: (state, action) => {
            state.muvReviews.data = action.payload;

            if(state.muvReviews.results){
                if(action.payload?.review_data){
                    state.muvReviews.results = state.muvReviews.results?.concat(action.payload?.review_data);
                }
            }
            else{
                state.muvReviews.results = action.payload?.review_data;
            }
           

        },  
        [lookUpMuvHotelReviews.rejected]: (state, action) => {
            state.muvReviews.data = null;
            state.muvReviews.results = null;
        }, 
        [bookHotelPackageThunk.fulfilled]: (state, action) => {
            state.bookingSuccess = true;
        },
        [bookHotelPackageThunk.rejected]: (state, action) => {
            state.loadingBooking = false;
            state.bookingSuccess = false;
        },
        [FetchCachedPullPrice.rejected]: (state, action) => {
            state.cachedHotel.loading = true;
        }, 
        [FetchCachedPullPrice.fulfilled]: (state, action) => {
            state.cachedHotel.data = action.payload;
            state.cachedHotel.loading = true;
        },
        [FetchCachedPullPrice.rejected]: (state, action) => {
          state.cachedHotel = initialState.cachedHotel;
        },
        
    },
    
})

export const {clearMuvHotelReviews,updateBookingInfo,clearHotelBooking,clearHotelReviews} = HotelSlice.actions
export const HotelBookingReducer = HotelSlice.reducer;