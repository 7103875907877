import Rating from '@material-ui/lab/Rating'
import React from 'react'
import ReviewsDescription from "./ReviewsDescription"
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Virtuoso } from 'react-virtuoso'
import { lookUpHotelReviews, lookUpMuvHotelReviews } from '../../BookingPage/PaymentPage/payment.thunk'
import { clearHotelReviews, clearMuvHotelReviews } from '../../BookingPage/PaymentPage/payment.slice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceFrown, faFaceLaugh, faFaceSmile } from '@fortawesome/free-solid-svg-icons'

const MuvHotelReviews_ = ({ establishment }) => {
    const hotelReviews = useSelector(state => state.HotelBooking.muvReviews);
    const reviewData = hotelReviews?.results;
    const hotel_id = establishment?.generalInfo?.establishmentPricelineID
    const [offset, setOffset] = React.useState(0)
    const dispatch = useDispatch()
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const findBy = searchParams.get("findBy")

    React.useEffect(() => {

        if (hotel_id) {
           try{
            dispatch(lookUpMuvHotelReviews({
                hotel_id: hotel_id,
                findBy: findBy || "pl"
            }))
           }
           catch{

           }
        }
        return () => {
            dispatch(clearMuvHotelReviews())
        }
    }, [hotel_id])



    return (

        <div>

            {reviewData &&
                <Virtuoso
                    overscan={200}
                    style={{
                        paddingBottom: 70,
                        height: 600
                    }}
                    totalCount={reviewData?.length}
                    itemContent={index => {
                        const review_idx = index;
                        const review = reviewData[review_idx];

                        const reviewDate = review?.creation_date ? moment(review.creation_date, 'YYYY-MM-DD').format("MMM Do[,] YYYY") : "";
                        const reviewUser = review?.user_name || "";
                        const reviewRating = review?.average_rating ? Number(review.average_rating) / 2 : 0;
                        const reviewDescription = review?.good_description || "";
                        const reviewBadDescription = review?.bad_description || "";
                        const reviewTitle = review?.review_text;
                        const travellerType = review?.traveller_type_name;

                        return (
                            <React.Fragment key={review_idx} >
                                <div key={review_idx + 'hotelReview'} style={{paddingBottom:8,borderBottom: "0.5px solid #dcdbdb" }} >
                                    {
                                        <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                                            <div style={{ flexGrow: "1", flexWrap: "wrap" }}>
                                                <div style={{ whiteSpace: "nowrap", }}><span style={{ font: "18px/24px Futura Md BT", color: "#707070" }}>{reviewUser}</span> {" - "} <span style={{ font: "14px/24px Futura Lt BT", color: "#707070", whiteSpace: "nowrap", }}>{reviewDate}
                                                </span>
                                                {travellerType && <span style={{ font: "14px/24px Futura Lt BT", color:"var(--mainGreen)", whiteSpace: "nowrap", }}> - {travellerType}
                                                </span>}
                                                </div>
                                                <Rating name="reviews_rating" style={{
                                                    color: "var(--mainGreen)", opacity: "1", lineHeight: "24px",
                                                }} precision={0.5} max={5} value={parseFloat(reviewRating)} disabled={true} />
                                            </div>

                                            {reviewTitle && <div style={{ whiteSpace: "nowrap", }}><span style={{ font: "18px/24px Futura Hv BT", color: "var(--mainBlack)" }}>{reviewTitle}</span> </div>}
                                            {reviewDescription && <div style={{ display:"flex",marginTop:5}}>
                                            <FontAwesomeIcon  size="xs" color={"#19BC9B"} icon={faFaceLaugh} style={{ marginRight: "10px" }} />  <ReviewsDescription description={reviewDescription} boxDescription={"box_3"} fontDescription={"16px/24px Futura Lt BT"} colorDescription={"#929292"} height={"90px"} width={"500px"} />
                                            </div>}
                                            {reviewBadDescription && <div style={{ display:"flex",marginTop:12}}>
                                            <FontAwesomeIcon  size="xs" color={"#19BC9B"} icon={faFaceFrown} style={{ marginRight: "10px" }} />  <ReviewsDescription description={reviewBadDescription} boxDescription={"box_3"} fontDescription={"16px/24px Futura Lt BT"} colorDescription={"#929292"} height={"90px"} width={"500px"} />
                                            </div>}
                                        </div>
                                    }

                                </div>
                            </React.Fragment>
                        )
                    }}
                />}

        </div>
    )
}

const mapStateToProps = (state) => ({
    establishment: state.FormBank.HotelSearch.establishment,
})

const mapDispatchToProps = {

}

const MuvHotelReviews = connect(mapStateToProps, mapDispatchToProps)(MuvHotelReviews_)
export default MuvHotelReviews
